.rango-fechas-panel .form-group {
	margin-bottom: 0;
}

.rango-fechas-panel {
	//background: red;
}

.rango-fechas-panel .btn-default {
    margin-top: 0px;
    padding: 6px;
}

@media (max-width: 768px) {
	.rango-fechas-panel .form-group.col-xs-12 {
	    margin-top: 10px;
	}
}

