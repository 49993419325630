
// Fonts
@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700');

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

// Easy docs
@import "easydocs";

//Animaciones
@import "animaciones";

//Panel de fechas
@import "rango-fechas-panel";

/*!
 * Start Bootstrap - Simple Sidebar (http://startbootstrap.com/)
 * Copyright 2013-2016 Start Bootstrap
 * Licensed under MIT (https://github.com/BlackrockDigital/startbootstrap/blob/gh-pages/LICENSE)
 */

 /*Esquema de colores 1*/
 //-5% $sidebar-background-color: #4E7288;
 $sidebar-background-color: #456478;
 //+5% $sidebar-background-color: #3c5768;
 $sidebar-font-color: #fff;
 $button-bg-color: #3E88B5;
 $button-border-color: #2a88bd;

 $buttonexcel-bg-color: #3E88B5;
 $buttonexcel-border-color: #2a88bd;

 $button-bg-default-color: #fff;


 /*Esquema de colores 2*/

//Personalización estilos bootstrap
.btn-primary {
    background-color:  $button-bg-color;
    border-color: $button-border-color;
    width: 100%;
    text-transform: uppercase;
    margin-top: 5px;
    padding: 10px;
&:focus,
  &.focus {
    color: $sidebar-font-color;
    background-color: darken($button-bg-color, 10%);
        border-color: darken($button-border-color, 25%);
  }
  &:hover {
    color: $sidebar-font-color;
    background-color: darken($button-bg-color, 10%);
        border-color: darken($button-border-color, 12%);
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: $sidebar-font-color;
    background-color: darken($button-bg-color, 10%);
        border-color: darken($button-border-color, 12%);

    &:hover,
    &:focus,
    &.focus {
      color: $sidebar-font-color;
      background-color: darken($button-bg-color, 17%);
          border-color: darken($button-border-color, 25%);
    }
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    background-image: none;
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus,
    &.focus {
      background-color: $button-bg-color;
          border-color: $button-border-color;
    }
  }
}

.btn-row {
        //padding: 5px;
        //min-width: 30px;
        //margin: 1px;
        padding: 6px;
        min-width: 30px;
        margin: 6px 0 0 0;
}

.btn-primary.btn-excel {
    background-color:  $buttonexcel-bg-color;
    border-color: $buttonexcel-border-color;
    width: 100%;
    text-transform: uppercase;
    margin-top: 5px;
    padding: 10px;
&:focus,
  &.focus {
    color: $sidebar-font-color;
    background-color: darken($buttonexcel-bg-color, 10%);
        border-color: darken($buttonexcel-border-color, 25%);
  }
  &:hover {
    color: $sidebar-font-color;
    background-color: darken($buttonexcel-bg-color, 10%);
        border-color: darken($buttonexcel-border-color, 12%);
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: $sidebar-font-color;
    background-color: darken($buttonexcel-bg-color, 10%);
        border-color: darken($buttonexcel-border-color, 12%);

    &:hover,
    &:focus,
    &.focus {
      color: $sidebar-font-color;
      background-color: darken($buttonexcel-bg-color, 17%);
          border-color: darken($buttonexcel-border-color, 25%);
    }
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    background-image: none;
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus,
    &.focus {
      background-color: $button-bg-color;
          border-color: $button-border-color;
    }
  }
}


.btn-default {
    background-color:  $button-bg-default-color;
    border-color: $buttonexcel-border-color;
    width: 100%;
    text-transform: uppercase;
    margin-top: 5px;
    padding: 10px;
    color: $button-bg-color;
&:focus,
  &.focus {
    background-color: darken($button-bg-default-color, 0%);
    color: lighten($button-bg-color, 10%);
    border-color: lighten($buttonexcel-border-color, 10%);
  }
  &:hover {
    background-color: darken($button-bg-default-color, 0%);
    color: lighten($button-bg-color, 10%);
    border-color: lighten($buttonexcel-border-color, 10%);
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    background-color: darken($button-bg-default-color, 0%);
    color: lighten($button-bg-color, 10%);
    border-color: lighten($buttonexcel-border-color, 10%);
    &:hover,
    &:focus,
    &.focus {
    background-color: darken($button-bg-default-color, 0%);
    color: lighten($button-bg-color, 10%);
    border-color: lighten($buttonexcel-border-color, 10%);
        }
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    background-image: none;
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus,
    &.focus {
      background-color: $button-bg-color;
          border-color: $button-border-color;
    }
  }
}

//smart-table
  .st-sort-ascent:before{
    content: '\25B2';
  }

  .st-sort-descent:before{
    content: '\25BC';
  }
  .columna-fecha {
    width: 25%;
  }

  .columna-bool {
    width: 25%;
  }

//perso titulos paneles
h3.panel-title {
    font-size: 18px !important;
    color: $sidebar-background-color;
}

//Para "juntar" los botones en móvil (>480px)
@media (max-width: 480px) {
.botones-panel div {
    padding-left: 3px;
    padding-right: 3px;}
}

//para ocultar los controles en tipo number
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

//Clases a medida
.maincontainer {
margin-left: 0;
padding-left: 0;
padding-right: 0;
}

.fa-menu {
	margin-left: -20px;
    padding-right: 5px;
}

img.img-responsive.logo {
    margin: 0 auto;
    height: 78px;
    //cursor: pointer;
}

.logo-ico-css {
  margin: 0 auto;
  text-align: center;
  font-weight: 800;
  font-size: 30px;
  width: 50px;
  height: 50px;
  border-radius: 12%;
  color: #fff;
  line-height: 50px;
  text-align: center;
}

.logo-ico-css.color1 {
  background: #68430F;
}
.logo-ico-css.color2 {
  background: #68671F;
}
.logo-ico-css.color3 {
  background: #BE9271;
}


.logo-texto-css {
  margin: 0 auto;
  text-align: center;
  font-weight: 800;
  font-size: 18px;
}

.cabecera-logo {
  min-height: 100px;
}

a>i {cursor: pointer;}

.barra-botones div {
    padding: 0 5px;
}

.icon-button {
    margin-right: 5px;
}

@media (max-width: 1200px) {
  .icon-button {
      font-size: 1.5em;
  }
}

//Bloquear el giro de movil
/* if portrait mode is detected, rotate the entire site -90 degrees to hint rotating to landscape */
// @media (orientation: landscape) {
//   body {
//     -webkit-transform: rotate(-90deg);
//     -moz-transform: rotate(-90deg);
//     -o-transform: rotate(-90deg);
//     -ms-transform: rotate(-90deg);
//     transform: rotate(-90deg);
//   }
// }

/*!
 * startbootstrap-simple-sidebar
 * MENU LATERAL 
 */

 //tabs
 .nav-tabs {
    margin-bottom: 10px;
}

//panel de conceptos (dentro de tablas)

.panel.conceptos {
    //background: #eee;
    padding-bottom: 0;
    margin-bottom: 20px;
}

.valor {
    padding: 6px 14px;
    border: 1px solid #ddd;
    border-radius: 4px 4px 4px 4px;
    -moz-border-radius: 4px 4px 4px 4px;
    -webkit-border-radius: 4px 4px 4px 4px;
}

.panel-gap {
margin-right: 8px;
margin-left: 8px;
}

.center-block {
margin: 0 auto;
}

.inicio-oculto {
    visibility: hidden !important;
}

.inicio-oculto.disponible {
    visibility: visible !important;
}

//elementos de formulario
p.small_label_info {
    font-size: 0.8em;
    font-weight: 400;
    color: #aaa;
    margin:0;
}

//personalización inputs prefijo-número de cliente-sufijo
.atenuado {
    color: #aaa;
}

@media (max-width: 768px) {
    .num_factura div{
        margin: 10px 0;
    }
}

//ESTILOS PARA LISTAS RENDERIZADAS POR AUTOCOMPLETE
// .ui-autocomplete .ui-menu-item:hover, .ui-autocomplete .ui-menu-item .ui-state-focus {
//     background: #eee !important;
//     border: 1px solid #eee;
//     color: #555; 
// }

.ac-option:hover {
    background: #eee !important;
    border: 1px solid #eee;
    color: #555; 
}

.ac-option {
  width: 100%;
  display: block;
  padding: 3px 0;
}

@media (max-width: 768px) {
    p.small_label_info {
        display: inline-block;
    }
}

//Validaciones
//.ng-valid.ng-dirty { color: red; }
//.ng-invalid.ng-dirty { color: red; }
.validation-invalid { 
    color: $brand-danger;
}

//Borde de campo que ha sido validado y es incorrecto (onBlur o onSubmit)
input.form-control.ng-touched.ng-valid-parse.ng-invalid, .ng-submitted input.form-control.ng-invalid, input.form-control.ng-touched.ng-invalid {
    border: 1px solid $brand-danger;
}

.help-block.error {
    color: $brand-danger;
}

//Otra opción para los estilos de los errores puede ser:
//<div class="form-group col-xs-12 col-sm-3 col-md-3  col-lg-3" ng-class = "{ 'has-error' : operacionesForm.importe.$invalid && (operacionesForm.importe.$touched || operacionesForm.$submitted) }">

//tabla de avisos clicable
#noticesListTable, #recordInvoiceGeneratedTable {
    tr:hover {
        background: lighten($button-bg-color, 50%);
        cursor: pointer;
    }
}

//datepicker
.ui-datepicker table {
    width: 100%;
    font-size: 1em;
    border-collapse: collapse;
    margin: 0 0 .6em;
}

.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default, 
.ui-button, html .ui-button.ui-state-disabled:hover, html .ui-button.ui-state-disabled:active {
    border: 1px solid rgba(0,0,0,0);
    font-size: 14px;
}

// perso lsh.je
@media (min-width: 768px) {
    .container {
    width: 100%;
    max-width: 1170px;
    } //lsh.jeg desactivamos el tamaño por defecto de bootstrap para que no se salga de la pantalla cuando el menu está desplegado

    .navbar-static-top {
        //display: none; //inicialmente estaba activado para ocultarse en 768px pero he preferido dejarlo visible
        margin: 0;
    }

    .container.login {
        margin-top: 60px;
    }
    	
    #sidebar-wrapper {
        margin-top: 0px; //lsh.je
    }

    .sidebar-nav {
    padding: 0px 0px 0 0; //lsh.je
    }
}

div#panel-login {
    padding: 15px;
}

//tabla adaptable
@media (max-width: 992px) {
    /* Force table to not be like tables anymore */
    #listEmployeesTable {
        table, thead, tbody, th, td, tr { 
            display: block; 
        }
        
        thead tr { 
            position: absolute;
            top: -9999px;
            left: -9999px;
        }
        
        tr { border: 1px solid #ccc; }
        
        td { 
            /* Behave  like a "row" */
            border: none;
            border-bottom: 1px solid #eee; 
            position: relative;
            padding-left: 40% !important; 
        }
        
        td:before { 
            /* Now like a table header */
            position: absolute;
            /* Top/left values mimic padding */
            top: 6px;
            left: 6px;
            width: 45%; 
            padding-right: 10px !important; 
            white-space: nowrap;
        }
        
        /*Label the data*/
        td:before { content: attr(data-label); }
    }
}
//FIN tabla adaptable


@media (max-width: 767px) {


    .container-fluid {
        padding-left: 0;
    } //lsh.jeg para no tener margenes en móvil

    .panel-body {
        padding: 15px;
        padding-left: 0px;
        padding-right: 0px;
    } //lsh.jeg para no tener margenes en móvil

	#sidebar-wrapper {
    margin-top: -22px; //lsh.je
	}

	.sidebar-nav {
    padding: 20px 0px 0 0; //lsh.je
	}

    #wrapper.toggled #page-content-wrapper {
        opacity: 0.3;
    }

}

.sidebarmenu li.fin_grupo {

    margin-bottom: 20px;
    //padding-bottom: 20px;
    //background: red;
}

li.menuseccion {
    color: darken($button-bg-color, 25%) !important;
    font-weight: 600;
}

li.menuseccion2 {
    color:  darken($button-bg-color, 25%) !important;
    font-weight: 600;
    padding-left: 10px;
}


li.menuseccion-intermedio {
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px rgba(255,255,255, 0.05) solid;
}


#empresas-list, #views-list {
    border-bottom: 1px solid lighten($sidebar-background-color, 10%);

}

.sidebarmenu {
    padding-top: 20px;
}

//perso para menu accordion
.sidebarmenu .panel-heading a {
    color: #fff !important;
    font-weight: 600;
    text-decoration: none;
}

.sidebarmenu .panel {
    border: 0;
    margin-top: 5px !important;
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0);
}

.sidebarmenu .panel-default, .sidebarmenu .panel-heading {
    border: 0;
    background: transparent;
    margin-top: 2px;
}

.sidebarmenu .collapse.in {
    background: lighten($sidebar-background-color, 5%);
}

a.accordion-link {
    display: block;
    cursor: pointer;
    padding: 0px 5px 5px 10px;
}

.sidebarmenu.panel-heading {
    padding: 0;
}

.panel-active {
    background: darken($sidebar-background-color, 5%) !important;
}

.sidebarmenu hr {
    border-top: 1px rgba(255,255,255, 0.05) solid;
    margin-top: 5px;
    margin-bottom: 5px;
}

//para el botón de menu
.navbar-toggle.collapsed {
   border: none;

  .top-bar {
    transform: rotate(0);
  }
  .middle-bar {
    opacity: 1;
  }
  .bottom-bar {
    transform: rotate(0);
  }
}

.navbar-toggle {
  border: none;
  background: transparent !important;

  &:hover {
    background: transparent !important;
  }

  .icon-bar {
    width: 22px;
    transition: all 0.2s;
  }
  .top-bar {
    transform: rotate(45deg);
    transform-origin: 10% 10%;
  }
  .middle-bar {
    opacity: 0;
  }
  .bottom-bar {
    transform: rotate(-45deg);
    transform-origin: 10% 90%;
  }
}

//-- fin perso lsh.je

 body {
    overflow-x: hidden;
 }

/* Toggle Styles */

.navbar-toggle {
    position: relative;
    float: left;
    margin-left: 15px;
    margin-right: 10px;
}

//perso para mantener barra de navegación en modo >768px
@media (min-width: 768px) {
  .navbar-toggle {
    display: block;
  }
  .navbar > .container .navbar-brand, .navbar > .container-fluid .navbar-brand {
    margin-left: 0px;
  }
}
.navbar-header {
    position: absolute;
    left: 0;
    margin-left: 0 !important;
}
//FIN perso para mantener barra de navegación en modo >768px

//perso para navbar sticky
body { padding-top: 50px; }
// FIN perso

#wrapper {
    padding-left: 0;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    // padding-left: 10px;
}

#sidebar-wrapper {
    z-index: 1000;
    position: fixed;
    left: 250px;
    width: 0;
    height: 100%;
    margin-left: -250px;
    overflow-y: auto;
    background:  $sidebar-background-color;//22323B;//6b9dbb;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    // width: 10px;
}

#sidebar-wrapper.desarrollo {
  background: black;
}


#page-content-wrapper {
    width: 100%;
    position: absolute;
    padding: 15px;
    padding-left: 0;
    padding-right: 0;
}



#wrapper.toggled {
    padding-left: 250px;
}

#wrapper.toggled #sidebar-wrapper {
    width: 250px;
}

#wrapper.toggled #page-content-wrapper {
    position: absolute;
    margin-right: -250px;
}

/* Sidebar Styles */
.sidebar-nav {
    position: absolute;
    top: 0;
    width: 250px;
    margin: 0;
    overflow-y: auto;
    height: 100%;
    min-height: 750px;
}

.sidebarmenu {
    margin: 0;
    padding-left: 0;
    list-style: none;
}

.sidebarmenu li {
    text-indent: 20px;
    line-height: 50px;
}

.sidebarmenu li {
    color:  $sidebar-font-color;
}

.sidebarmenu li a {
    display: block;
    text-decoration: none;
    color:  $sidebar-font-color;
    padding-left: 20px;
}

.sidebarmenu li a:hover {
    text-decoration: none;
    color:  $sidebar-font-color;
    background: lighten($sidebar-background-color, 10%);
}

.sidebarmenu li a:active {
    text-decoration: none;
    background: lighten($sidebar-background-color, 20%); 
}

.sidebarmenu li.active {
    font-weight: 600;
}

.sidebarmenu li.active:before {
   font-family: FontAwesome;
    content: "\f0da";
    font-weight: 100;
    position: absolute;
    height: auto;
    left: 5px;
}

.ssidebarmenu > .sidebar-brand {
    height: 65px;
    font-size: 18px;
    line-height: 60px;
}

.sidebarmenu > .sidebar-brand a {
    color: #999999;
}

.sidebarmenu > .sidebar-brand a:hover {
    color: #fff;
    background: none;
}

@media(min-width:768px) {
    #wrapper {
        padding-left: 250px;
    }


    #sidebar-wrapper {
        width: 250px;
    }

    #page-content-wrapper {
        padding: 20px;
        position: relative;
    }

    #wrapper.toggled #sidebar-wrapper {
        width: 0;
    }

    #wrapper.toggled {
        padding-left: 0;
    }
    #wrapper.toggled #page-content-wrapper {
        position: relative;
        margin-right: 0;
    }
}

//perso formularios
//para que los grupos de formularios en dos columnas no aparezcan juntos (por defecto margin -15px)
.form-2-columns .form-group {
    margin-left: 0; 
    margin-right: 0;
}
.form-control.readonly {
    background: #eeeeee;
}

.multiSelect > button {
  min-width: 100% !important;
  max-width: 100% !important;
}
.multiSelect .checkBoxContainer {
  max-width: 330px;
}
