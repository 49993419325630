.panel-active{
  color: white !important;
}
.fa-btn{
  margin-right: 10px;
}

.edit-in-place span {
  cursor: pointer;
}

.edit-in-place input {
  display: none;
}

.edit-in-place.active span {
  display: none;
}

.edit-in-place.active input {
  display: inline-block;
}
